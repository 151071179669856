import React, {Component, KeyboardEvent} from "react"
import styles from "./InputMaskNumber.module.css"
import InputMask from "react-input-mask"

interface Props {
    placeholder?: string
    onChange: (value: string) => void
    disabled?: boolean
    value: string
    type?: "text" | "email" | "tel" | "date" | "password" | "number"
    mask: string
    classNameWrap?: string
    className?: string
    onKeyDown?: (event: KeyboardEvent<Element>) => void
}

export class InputMaskNumber extends Component<Props, {}> {
    private onChange(event) {
        this.props.onChange(event.target.value)
    }

    public render() {
        const {placeholder, disabled, value, onKeyDown, mask, type = "text"} = this.props

        return (
            <div>
                <InputMask
                    className={styles.root}
                    mask={mask}
                    value={value}
                    onChange={this.onChange.bind(this)}
                    placeholder={placeholder}
                />
            </div>
        )
    }
}
