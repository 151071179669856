import React, {Component, KeyboardEvent} from "react"
import styles from "./input.module.css"

interface Props {
    placeholder?: string
    onChange: (value: string) => void
    disabled?: boolean
    value: string
    type?: "text" | "email" | "tel" | "date" | "password" | "number"
    onKeyDown?: (event: KeyboardEvent<Element>) => void
}

export class Input extends Component<Props, {}> {
    private onChange(event) {
        this.props.onChange(event.target.value)
    }

    public render() {
        const {placeholder, disabled, value, onKeyDown, type = "text"} = this.props
        return (
            <input
                className={styles.input}
                type={type}
                value={value}
                onChange={this.onChange.bind(this)}
                placeholder={placeholder}
                disabled={disabled}
                onKeyDown={onKeyDown}
                autoComplete="off"
            />
        )
    }
}
